import React from 'react';
import { InView } from 'react-intersection-observer';
import { Transition } from '@headlessui/react';

export enum FeaturePositions {
  Left = 'left',
  Right = 'right',
}

export interface FeatureProps {
  title: string,
  description: string,
  panel: JSX.Element,
  position: FeaturePositions,
  features: {
    name: string,
    description: string,
    icon: any,
  }[],
}

/**
 * A modular feature component.
 */
export default function Feature({
  title, description, panel, position, features,
}: FeatureProps) {
  const [inView, setInView] = React.useState(false);

  if (position === FeaturePositions.Left) {
    return (
      <div className="relative mt-12 sm:mt-16 lg:mt-24">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="lg:col-start-2">
            <InView onChange={setInView} threshold={0.5} triggerOnce>
              <Transition show={inView} className="animate__animated animate__fadeInRight">
                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                  {title}
                </h3>
                {description && (
                  <p className="mt-3 text-lg text-gray-500">{description}</p>
                )}

                <dl className="mt-10 space-y-10">
                  {features.map((item) => (
                    <div key={item.name} className="relative">
                      <dt>
                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 text-white hover:bg-AKABENI-dark hover:scale-125 transition-transform duration-500 transform-gpu">
                          <item.icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                          {item.name}
                        </p>
                      </dt>
                      <dd className="mt-2 ml-16 text-base text-gray-500">
                        {item.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </Transition>
            </InView>
          </div>

          <InView onChange={setInView} threshold={0.5} triggerOnce>
            <Transition show={inView} className="animate__animated animate__fadeInLeft">
              <div
                className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1"
                aria-hidden="true"
              >
                {/* Image or any component */}
                {panel && panel}
              </div>
            </Transition>
          </InView>
        </div>
      </div>
    );
  }
  return (
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <InView onChange={setInView} threshold={0.5} triggerOnce>
          <Transition show={inView} className="animate__animated animate__fadeInLeft">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              {title}
            </h3>
            {description && (
              <p className="mt-3 text-lg text-gray-500">{description}</p>
            )}

            <dl className="mt-10 space-y-10">
              {features.map((item) => (
                <div key={item.name} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-gray-500 text-white hover:bg-blue-900 hover:scale-125 transition-transform duration-500 transform-gpu">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">
                      {item.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {item.description}
                  </dd>
                </div>
              ))}
            </dl>
          </Transition>
        </InView>
      </div>

      <InView onChange={setInView} threshold={0.5} triggerOnce>
        <Transition show={inView} className="animate__animated animate__fadeInRight">
          <div
            className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1"
            aria-hidden="true"
          >
            {/* Image or any component */}
            {panel && panel}
          </div>
        </Transition>
      </InView>
    </div>
  );
}
