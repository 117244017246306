import React from 'react';
import { InView } from 'react-intersection-observer';
import { Transition } from '@headlessui/react';
import SafeLink from './SafeLink';

interface GetReadySectionProps {
  title: string,
  subtitle: string,
}

export default function GetReadySection({ title, subtitle }: GetReadySectionProps) {
  const [inView, setInView] = React.useState(false);
  return (
    <InView onChange={setInView} threshold={0.7} triggerOnce>
      <Transition show={inView} className="animate__animated animate__bounceIn">
        <div className="bg-gray-50">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <h2 className="text-3xl font-extrabold tracking-tight text-blue-gray-500 sm:text-4xl">
              <span className="block">{title}</span>
              <span className="block text-blue-gray-900">{subtitle}</span>
            </h2>
            <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
              <div className="inline-flex rounded-none shadow">
                <SafeLink
                  isExternal
                  to="https://docs.taichi.graphics/get-started"
                  className="bg-black border border-transparent rounded-none py-3 px-5 inline-flex items-center justify-center text-base font-medium text-gray-100 hover:bg-gray-700 hover:text-white"
                >
                  Get started
                </SafeLink>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </InView>
  );
}
