import React, { useState, useEffect } from 'react';

interface TextLoopProps {
  children: (JSX.Element | string)[],
  interval: number,
}

/**
 * A component for looping between
 * the text with animaions.
 */
export default function TextLoop({ children, interval = 3600 }: TextLoopProps) {
  const [currTextIndex, setcurrTextIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const textArray = React.Children.toArray(children);

  const updateTextIndex = (index: number) => {
    setIsVisible(false);
    setcurrTextIndex(index);
    setIsVisible(true);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      // Round-Robin
      if (currTextIndex >= textArray.length - 1) {
        updateTextIndex(0);
      } else {
        updateTextIndex(currTextIndex + 1);
      }
    }, interval);
    return () => {
      clearInterval(timer);
    };
  }, [currTextIndex]);

  return (
    <>
      {isVisible && (
        <div className="animate__animated animate__faster animate__fadeInDown">
          {textArray[currTextIndex]}
        </div>
      )}
    </>
  );
}
