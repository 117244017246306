import React from 'react';
import SafeLink from './SafeLink';

export interface AnchorBlackButtonProps {
  isExternal: boolean,
  href: string,
  text: string,
}

/**
 * An atomic black anchor button with white
 * border component.
 */
export default function AnchorBlackButton({ isExternal, href, text }: AnchorBlackButtonProps) {
  return (
    <SafeLink
      isExternal={isExternal}
      to={href}
      className="flex items-center justify-center px-4 py-3 text-base font-medium shadow-sm sm:px-8 border text-white bg-black bg-opacity-50 hover:bg-white hover:text-black border-white transition duration-300 ease-in-out"
    >
      {text}
    </SafeLink>
  );
}
