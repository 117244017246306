import React from 'react';
// import DotDecoration, { DotDecorationPositions } from './DotDecoration';
import Feature, { FeatureProps } from './Feature';

interface FeatureSectionProps {
  title: string,
  description: string,
  features: FeatureProps[],
}

/**
 * The feature section composite component,
 * only for demonstrating features.
 */
export default function FeatureSection({ title, description, features }: FeatureSectionProps) {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
        {/* FIXME: gatsby build generates weird position for this component */}
        {/* <DotDecoration position={DotDecorationPositions.Right} /> */}

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            {title}
          </h2>
          {description && (
            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
              {description}
            </p>
          )}
        </div>

        {features.map((feature) => (
          <Feature
            key={feature.title}
            title={feature.title}
            description={feature.description}
            panel={feature.panel}
            position={feature.position}
            features={feature.features}
          />
        ))}

        {/* FIXME: gatsby build generates weird position for this component */}
        {/* <DotDecoration position={DotDecorationPositions.Left} /> */}
      </div>
    </div>
  );
}
