import React from 'react';
import TypeIt from 'typeit-react';
import TextLoop from './TextLoop';
import AnchorBlackButton from './AnchorBlackButton';

interface HeroProps {
  backgroundAsset: JSX.Element,
  description: string,
}

/**
 * The hero section component.
 */
export default function HeroSection({ backgroundAsset, description }: HeroProps) {
  return (
    <>
      <div className="relative h-screen">
        <div className="absolute inset-x-0 bottom-0 bg-gray-900 h-full" />
        <div className="mx-auto h-full">
          <div className="relative shadow-xl sm:overflow-hidden h-full">
            {backgroundAsset && (
              <div className="absolute inset-0">
                {/* FIXME: This may not render correctly */}
                <div className="absolute inset-0 w-full h-full object-cover">
                  {backgroundAsset}
                </div>
                <div className="absolute inset-0 bg-gray-500 mix-blend-multiply" />
              </div>
            )}

            <div className="relative px-4 py-72 sm:px-6 sm:py-72 lg:py-72 lg:px-8">
              <h1 className="text-center text-4xl tracking-tight font-bold text-gray-100 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                <TextLoop interval={2000}>
                  <span className="block xl:inline bg-gradient-to-r from-purple-300 to-red-300 bg-clip-text text-transparent">
                    Graphics
                    {' '}
                  </span>
                  <span className="block xl:inline bg-gradient-to-r from-green-300 to-purple-300 bg-clip-text text-transparent">
                    Visual Effects
                    {' '}
                  </span>
                  <span className="block xl:inline bg-gradient-to-r from-indigo-300 to-yellow-300 bg-clip-text text-transparent">
                    Differentiable
                  </span>
                </TextLoop>
                <span className="block">programming for</span>
                {' '}
                <TypeIt
                  options={{
                    speed: 200,
                    loop: false,
                    cursorSpeed: 2000,
                    cursor: false,
                    waitUntilVisible: true,
                  }}
                >
                  <span>everyone</span>
                </TypeIt>
              </h1>
              <p className="mt-6 max-w-lg mx-auto text-center text-xl text-gray-400 sm:max-w-3xl">
                {description}
              </p>
              <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-2 sm:gap-5">
                  <AnchorBlackButton isExternal href="https://docs.taichi.graphics/get-started" text="Get Started" />

                  <AnchorBlackButton isExternal href={`${process.env.GATSBY_DOCS_LINK}`} text="Documentation" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
