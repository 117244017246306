import React from 'react';

interface MetricsProps {
  image: string,
  imageAlt: string,
  title: string,
  subtitle: string,
  description: string,
  metrics: {
    id: number,
    stat: string,
    emphasis: string,
    rest: string,
  }[],
}

/**
 * A component for displaying metrics.
 */
export default function MetricsSection({
  image, imageAlt, title, subtitle, description, metrics,
}: MetricsProps) {
  return (
    <div className="relative bg-gray-900">
      {image && (
        <div className="h-80 absolute inset-x-0 bottom-0 xl:top-0 xl:h-full">
          <div className="h-full w-full xl:grid xl:grid-cols-2">
            <div className="h-full xl:relative xl:col-start-2">
              <img
                className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                src={image}
                alt={imageAlt}
              />
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
              />
            </div>
          </div>
        </div>
      )}
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-2 xl:grid-flow-col-dense xl:gap-x-8">
        <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-start-1 xl:pb-24">
          <h2 className="text-sm font-semibold tracking-wide uppercase">
            <span className="bg-gradient-to-r from-purple-300 to-red-300 bg-clip-text text-transparent">
              {title}
            </span>
          </h2>
          <p className="mt-3 text-3xl font-extrabold text-white">
            {subtitle}
          </p>
          <p className="mt-5 text-lg text-gray-300">{description}</p>
          <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
            {metrics.map((metric) => (
              <p key={metric.id}>
                <span className="block text-2xl font-bold text-white">
                  {metric.stat}
                </span>
                <span className="mt-1 block text-base text-gray-300">
                  <span className="font-medium text-white">
                    {metric.emphasis}
                  </span>
                  {' '}
                  {metric.rest}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
