import React, { useState, useEffect } from 'react';
// import shuffle from 'lodash/shuffle';
import VulkanSVG from './VulkanSVG';
import CUDASVG from './CUDASVG';
import WASMSVG from './WASMSVG';
import JSSVG from './JSSVG';
import CPPSVG from './CPPSVG';
import LLVM from './LLVM';
import MetalSVG from './MetalSVG';
import OpenGLSVG from './OpenGLSVG';
import ARMSVG from './ARMSVG';
import Intel64SVG from './Intel64SVG';
import AMD64SVG from './AMD64SVG';

const portabilityData = [
  <CUDASVG className="transform scale-400 opacity-60" />,
  <WASMSVG className="transform scale-300 opacity-60" />,
  <JSSVG className="transform scale-300 opacity-60" />,
  <CPPSVG className="transform scale-400 opacity-60" />,
  <LLVM className="transform scale-400 opacity-60" />,
  <MetalSVG className="transform scale-400 opacity-60" />,
  <OpenGLSVG className="transform scale-400 opacity-60" />,
  <ARMSVG className="transform scale-400 opacity-60" />,
  <Intel64SVG className="transform scale-400 opacity-60" />,
  <AMD64SVG className="transform scale-500 opacity-60" />,
  <VulkanSVG className="transform scale-500 opacity-60" />,
];

export interface AnimatedTaichiPortabilityProps {
  interval?: number,
}
/**
 * An animation component to demonstrate Taichi's portability.
 */
export default function AnimatedTaichiPortability({
  interval = 7200,
}: AnimatedTaichiPortabilityProps) {
  const [items, setItems] = useState(portabilityData);

  useEffect(() => {
    // TODO: use react-spring to add shuffle effects, i.e. shuffle(items)
    const t = setInterval(() => setItems(items), interval);
    return () => clearInterval(t);
  }, []);

  return (
    <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
      {items.map((item, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={idx} className="col-span-1 flex justify-center py-8 px-8 bg-gray-50 shadow-2xl hover:animate-wiggle">
          {item}
        </div>
      ))}
    </div>
  );
}
